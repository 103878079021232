import React, { createContext, useContext, useEffect, useState } from "react";
const FacebookSDKContext = createContext(null);

export function FacebookSDKProvider({ children }) {
    const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(null);
    const [facebookPageAccessTokens, setFacebookPageAccessTokens] = useState(null);
    const [instagramAccessToken, setInstagramAccessToken] = useState(null);

    useEffect(() => {
        const instagramAccessToken = localStorage.getItem("FG_INSTAGRAM_ACCESS_TOKEN_RESPONSE");
        const facebookAccessToken = localStorage.getItem("FG_FACEBOOK_ACCESS_TOKEN_RESPONSE");
        const facebookPageAccessTokens = localStorage.getItem("FG_FACEBOOK_PAGE_ACCESS_TOKENS");
        if (instagramAccessToken) {
            setInstagramAccessToken(instagramAccessToken);
        }
        if (facebookAccessToken) {
            setFacebookUserAccessToken(facebookAccessToken);
        }
        if (facebookPageAccessTokens) {
            setFacebookPageAccessTokens(facebookPageAccessTokens);
        }
    }, []);

    return (
        <FacebookSDKContext.Provider
            value={{
                facebookUserAccessToken,
                setFacebookUserAccessToken,
                facebookPageAccessTokens,
                setFacebookPageAccessTokens,
                instagramAccessToken,
                setInstagramAccessToken,
            }}
        >
            {children}
        </FacebookSDKContext.Provider>
    );
}

export function useFacebookSDK() {
    const context = useContext(FacebookSDKContext);
    if (context === null) {
        throw new Error("useFacebookSDK must be used within a FacebookSDKProvider");
    }
    return context;
}
